
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  //staging
  baseUrl = "https://hubadminapi.heinekensalesloyalty.bigcityvoucher.co.in/v1";

  //production
  //baseUrl = "https://hubadminapi.silverchampionsleague.in/v1";

  // Preeti Local url
//  baseUrl="http://192.168.1.178/heineken/heineken_sales_loyalty_hubadmin_api/web/v1";


  login = this.baseUrl + "/login/login";

  // Dashboard
  dashboard = this.baseUrl + '/report/dash-board';
  dashboardDistribution = this.baseUrl + '/report/distribution'
  dashboardRepeat = this.baseUrl + '/report/repeat-purchase'
  dashboardVolume = this.baseUrl + '/report/readvolume'

  //targets
  getTargetsList = this.baseUrl + "/admin/list-target-config";
  addTarget = this.baseUrl + "/admin/tagert-configuration";
  uploadTarget = this.baseUrl + "/admin/upload-targets";
  listOutletTargets = this.baseUrl + "/admin/list-outlet-targets"

  //User Management
  userRegistration = this.baseUrl + "/login/registration-upload";
  getAllUsers = this.baseUrl + '/admin/get-users'
  getRole = this.baseUrl + '/admin/getrole'
  // postData = this.baseUrl + '/admin/get-users?status=&user_role_id=&search'


  //visibility uploads
  visibilityUploads = this.baseUrl + "/test/visibility-uploads-in-hierarchy";
  getVisibilty = this.baseUrl + "/admin/get-visibility-points"

  //wall of fame
  wallOfFameUploads = this.baseUrl + "/admin/wall-of-fame-uploads";
  wallOfFame = this.baseUrl + "/admin/wall-of-fame"
  updateWof=this.baseUrl + "/admin/update-walloffame";  



  //vouchers
  getVouchersList = this.baseUrl + "/admin/get-vouchers";
  addVoucher = this.baseUrl + "/admin/add-vouchers";
  updateVoucher = this.baseUrl + "/admin/update-vouchers";

  //voucher code
  getVoucherCode = this.baseUrl + "/admin/get-voucher-codes";
  uploadVoucherCode = this.baseUrl + "/admin/upload-voucher-codes";

  // reportadmin

  reportDashboard = this.baseUrl + '/report/dash-board'
  reportDistribution = this.baseUrl + '/report/distribution'
  reportRepertpurchase = this.baseUrl + '/report/repeat-purchase'
  reportvolume = this.baseUrl + '/report/readvolume'
  //reports
  hierachyReport = this.baseUrl + '/report/hierachy-report'
  walloffram = this.baseUrl + '/report/walloffame-report'
  sales = this.baseUrl + '/report/sales-performancereport'
  repeatpurchase = this.baseUrl + '/report/repeat-purchasereport'
  distribution = this.baseUrl + '/report/distribution-report'
  leaderboard = this.baseUrl + '/report/monthly-userpointsreport'
  visibility = this.baseUrl + '/report/visibility-report'
  loginReport = this.baseUrl + '/report/loginreport'
  winnersReport = this.baseUrl + '/report/get-userwinningsreport'

  //hubadmin 
  //general setting 
  generalSetting = this.baseUrl + '/admin/general-settings';

  //outlet 
  getOutlets = this.baseUrl + '/admin/get-outlets';
  updateOutlet = this.baseUrl + '/admin/update-outlet';
  migrateOutlet = this.baseUrl + "/admin/migarate-outlets";

  //user-outlet mapping
  getUserOutlets = this.baseUrl + '/admin/get-user-outlet-mapping';
  updateUser = this.baseUrl + '/admin/update-user';
  changeLog = this.baseUrl + "/admin/change-log";
  
  //achievement
  getAchievement = this.baseUrl + "/admin/outlets-acivement"
  uploadAchievement = this.baseUrl + "/admin/day-wise-salesinhierarchy"
  getDistribution = this.baseUrl +  "/admin/list-distribution-achivement"
  getRepeat = this.baseUrl + "/admin/list-repeat-achivements"
  getUserAchievements = this.baseUrl + "/admin/user-achivments"



  //states 
  getStates = this.baseUrl + '/admin/get-states';

  //regions
  getRegions = this.baseUrl + '/admin/get-regions';


  //kpis and milestone 
  getKpis = this.baseUrl + '/admin/getkpis';
  updateKpis = this.baseUrl + '/admin/add-kpis';
  getMileStoneSlabs = this.baseUrl + '/admin/get-milestone-slabs';


  // Voucher Admin

  //vouchers
  getAdminVouchersList = this.baseUrl + "/voucheradmin/get-vouchers";
  addAdminVoucherList = this.baseUrl + "/voucheradmin/add-vouchers";


  //voucher code
  getVoucherAdminCodes = this.baseUrl + "/voucheradmin/get-voucher-codes";
  uploadVoucherAdminCodes = this.baseUrl + "/voucheradmin/upload-voucher-codes";
  //user Winning
  uploadUserWinning = this.baseUrl + '/voucheradmin/upload-user-winnings';
  getUserWinning = this.baseUrl + '/voucheradmin/get-userwinnings';


}
export interface ApiConfigurationInterface { }