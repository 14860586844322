
export const colorCodes: any = [
  "#D9E3F0",
  "#F47373",
  "#697689",
  "#37D67A",
  "#2CCCE4",
  "#555555",
  "#dce775",
  "#ff8a65",
  "#ba68c8",
];


export const month_list: any = [
  {
    "abbreviation": "Jan",
    "name": "January",
    id: '01'
  },
  {
    "abbreviation": "Feb",
    "name": "February",
    id: '02'
  },
  {
    "abbreviation": "Mar",
    "name": "March",
    id: '03'
  },
  {
    "abbreviation": "Apr",
    "name": "April",
    id: '04'
  },
  {
    "abbreviation": "May",
    "name": "May",
    id: '05'
  },
  {
    "abbreviation": "Jun",
    "name": "June",
    id: '06'
  },
  {
    "abbreviation": "Jul",
    "name": "July",
    id: '07'
  },
  {
    "abbreviation": "Aug",
    "name": "August",
    id: '08'
  },
  {
    "abbreviation": "Sep",
    "name": "September",
    id: '09'
  },
  {
    "abbreviation": "Oct",
    "name": "October",
    id: '10'
  },
  {
    "abbreviation": "Nov",
    "name": "November",
    id: '11'
  },
  {
    "abbreviation": "Dec",
    "name": "December",
    id: '12'
  }
]
export const regularExpression: any = {
  email: "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}",
  alphanumeric: '/^[0-9a-zA-Z]+$/',
  noSpecialCharector: '^[0-9a-zA-Z \b]+$',
  latAndLang: '/^[0-9]*\.?[0-9]*$/',
  onlyNumAndSpecialChr: '([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))'
}

export const role_hierarchy: any = {
  "success": true,
  "status": 200,
  "data": {
    "1": {
      "parent_role_id": 0,
      "name": "Cargil",
      "children": {
        "2": {
          "parent_role_id": 1,
          "name": "Sales Director",
          "children": {
            "3": {
              "parent_role_id": 2,
              "name": "FSE",
              "children": {
                "4": {
                  "parent_role_id": 3,
                  "name": "Distributor"
                },
                "5": {
                  "parent_role_id": 3,
                  "name": "Retailer"
                }
              }
            }
          }
        }
      }
    },
    "2": {
      "parent_role_id": 1,
      "name": "Sales Director",
      "children": {
        "3": {
          "parent_role_id": 2,
          "name": "FSE",
          "children": {
            "4": {
              "parent_role_id": 3,
              "name": "Distributor"
            },
            "5": {
              "parent_role_id": 3,
              "name": "Retailer"
            }
          }
        }
      }
    },
    "3": {
      "parent_role_id": 2,
      "name": "FSE",
      "children": {
        "4": {
          "parent_role_id": 3,
          "name": "Distributor"
        },
        "5": {
          "parent_role_id": 3,
          "name": "Retailer"
        }
      }
    },
    "4": {
      "parent_role_id": 3,
      "name": "Distributor"
    },
    "5": {
      "parent_role_id": 3,
      "name": "Retailer"
    }
  }
}


export const TARGET_EXCEL_HEADER: any[] = ["outlet_code", "target_qty", "brand", "state"];
export const USER_EXCEL_HEADER: any[] = ["outlet_code", "outlet_name", "State", "City", "Region", "Depot", "ASM_Name", "ASM_Mobile_Number",
  "ASM_Email_Id", "ASE_Name", "ASE_Mobile_Number", "ASE_Email_Id", "TSE_Name",
  "TSE_Mobile_Number", "TSE_Email_Id", "JTSE_Name", "JTSE_Mobile_Number", "JTSE_Email_Id","Channel"];
  export const ACHIEVEMENT_EXCEL_HEADER: any[] = ["Date","outlet_code","Volume","Region"];
  
export const createCourse: any = {
  program_id: 1,
  course_description: "",
  course_ana_title: null,
  video_url: null,
  course_type: 0,
  points: null,
  frequency_id: "",
  start_date: "",
  expiry_date: "",
  pre_expiry_date: "",
  quiz_configured: "",
  quiz_points_criteria: "",
  realted_to_engagement: "",
  module_enabled: null,
  module_expired: null,
  course_title: "",
  course_image: null,
  course_content: null,
  instruction_en: null,
};


export class CampaignModel {
  id: Number;
  campaign_name: String;
  program_id: Number;
  start_date: String;
  end_date: String;
  active_hrs_start: String;
  active_hrs_end: String;
  duration_days: Number;
  terms: String;
  status: Number;
  created_date: String;
  updated_date: String;
  announcement: String;
  campign_hold: Boolean;
  is_visit_tracker: any;
}

export const searchUsers: any = {
  filter_search_geography: [],
  filter_search_channel: [],
  filter_search_region: [],
  filter_search_state: [],
  filter_search_city: [],
  filter_search_userrole: [],
};


export const updateEngagementDetails: any = {
  campaign_id: 0,
  task_type: 0,
  subtask_type: 0,
  success_points: 0,
  user_role_id: 0,
  task_question_id: 0,
  subtask_question_id: null,
  program_id: 1,
  task_frequency: 0,
  is_information: 0,
  campaign_activities_config_id: 0,
  quiz_points_criteria: 0,
  starttimeslot: null,
  endtimeslot: null,
  created_date: null,
  updated_date: null,
  has_subtask: false,
  description: null,
  instruction: "",
  title: "",
  subtitle: "",
  help: "",
  success_msg: null,
  failure_msg: null,
  show_answer: 0
};

export const sampleInstructionTemplatetemplate: any = `
    <div class="row">    <div class="col-12" text-center>        <p class="P-titlepic">Answer 4 simple questions to win</p>        <div class="main_i">            <div class="coin_image"><img class="c_image" src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/coin.png" style="" /> <p class="P-titlePoints">13 COINS</p></div>            <div class="coin_i">             </div>        </div>    </div>  </div>  <div class="row border" no-padding>    <div class="col-6" col-6 no-padding>        <div class="crads">            <div class="item item-avatar"><img src="https://api1.staging6.bigcityvoucher.co.in/uploads/content_learning/quiz_inst.png" style="max-width:400px;" /></div>        </div>    </div>    <div class="col-6" col-6 no-padding>        <div class="crads">            <div class="item item-avatar"></div>        </div>    </div>  </div>  <div class="row margin_i" padding>    <div class="row_inst" col-12 no-padding text-center>INSTRUCTIONS</div>  </div>  <div class="row margin_l">    <ol>        <li class="inst">Click on Start Quiz.</li>        <li class="inst">Select the right answers for all questions.</li>        <li class="inst">Earn assured points only if all questions are answered correctly.</li>    </ol>  </div>
`;

export const createQuizContentAndLearning: any = {
  question: "",
  course_id: 2,
  quiz_type: 0,
  options: [
    {
      option: "fgdf",
      is_right_option: 0,
      id: -1,
    },
    {
      option: "fgdf",
      is_right_option: 0,
      id: -1,
    },
  ],
  points: 0,
  program_id: 1,
};

export const courseContent: any = [
  {
    type: "img",
    data: "",
  },
  {
    type: "text",
    data: "",
  },
];

export const subQuizTypes: any = [
  {
    name: "Text",
    id: 1,
  },
  {
    name: "Image",
    id: 2,
  },
];

export const engagementRewardPoints: any = {
  rank: "Winner",
  points: "",
  isAdd: false,
  isRemove: false,
};

export const pointsDetails: any = {
  task_id: null,
  reward_type: 1,
  campaign_id: null,
  task_rank_based_reward: [],
  assured_type: 1,
  task_assured_fixed_point: { total_point: null },
  wof_title: "Wof Title",
  wof_configurations: [],
  max_winners: null,
  spin_btn_text: "Spin the wheel",
  frequency: 1,
  wof_configSettings: {
    denomination_text: "",
    weightage_percentage: null,
    wof_reward_type: 2,
    reward: null,
    color: "",
  },
};


export const weelOfFortune: any = {
  ispointsOrReward: 2,
  products: [
    {
      id: 1,
      name: "product A",
    },
  ],
  divisions: [
    {
      id: 1,
      name: "Wheighttage Percentage",
    },
  ],
  dinomination: "",
  probability: "",
  qty: "",
  points: "",
  isAdd: false,
};

export const weelOfFortuneConfig: any = {
  button_text: "",
  frequency: "",
  max_winners_day: "",
  max_winners_engagement: "",
};

export const creatActivity: any = {
  campaign_id: 1,
  campaign_name: "",
  activity_title: "",
  trigger_name: "",
  activity_type: null,
  points: null,
  frequency_id: 1,
  frequency_name: "",
  activity_per_day: null,
  days: null,
  set_trigger: 0,
  program_id: 1,
  status: 1,
  start_date: null,
  end_date: null,
  created_date: null,
  updated_date: null,
  activity_desc: null,
  reward: null,
  success_msg: "",
  success_notify: "",
  success_sms: " ",
  fail_msg: "",
  fail_notify: null,
  fail_sms: "",
  instruction: "",
  task_id: null,
  id: null,
};

export const taskIntroTemplate: any = {
  template: `
    <div class="row">
    <div class="col-12" text-center>
        <p class="P-titlepic">Answer 6 simple Bud4Trade questions to win</p>

        <div class="main_i">
            <div class="coin_image"><img class="c_image" src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/coin.png" style="" /></div>

            <div class="coin_i">
                <p class="P-titlePoints">13 COINS</p>
            </div>
        </div>
    </div>
  </div>

  <div class="row border" no-padding>
    <div class="col-6" col-6 no-padding>
        <div class="crads">
            <div class="item item-avatar"><img src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/quiz1.png" /></div>
        </div>
    </div>

    <div class="col-6" col-6 no-padding>
        <div class="crads">
            <div class="item item-avatar"><img src="https://api1.bud4trade.bigcityexperiences.com/uploads/engagement_images/quiz2.png" /></div>
        </div>
    </div>
  </div>

  <div class="row margin_i" padding>
    <div class="row_inst" col-12 no-padding text-center>INSTRUCTIONS</div>
  </div>

  <div class="row margin_l">
    <ol>
        <li class="inst">Click on Start Quiz.</li>

        <li class="inst">Select the right answers for all questions.</li>

        <li class="inst">Earn assured points only if all questions are answered correctly.</li>
    </ol>
  </div>
  `,
};